<template>
	<CalenderPlan />
</template>

<script>
import CalenderPlan from "@/components/research/modules/planAndManage/stages/CalenderPlan";

export default {
	name: "MentorCalendar",

	components: {
		CalenderPlan,
	},
};
</script>
